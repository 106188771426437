@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-text;
}

.prose > p {
  margin: 0;
}

.prose > ol {
  margin: 0;
}

.prose > ul {
  margin: 0;
}
